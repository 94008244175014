import * as THREE from "three";
import { ARButton } from "./ARButton";
import { TextGeometry } from "./TextGeometry";
import { FontLoader } from "./FontLoader";

var camera,
  controller,
  scene,
  renderer,
  font,
  group,
  textMesh1,
  textMesh2,
  textGeo,
  materials,
  message;

function createText(text) {
  textGeo = new TextGeometry(text, {
    font: font,
    size: 20,
    height: 5,
    curveSegments: 4,
    bevelThickness: 2,
    bevelSize: 1.5,
    bevelEnabled: true
  });

  textGeo.computeBoundingBox();

  const centerOffset =
    -0.5 * (textGeo.boundingBox.max.x - textGeo.boundingBox.min.x);

  textMesh1 = new THREE.Mesh(textGeo, materials);

  // textMesh1.position.x = centerOffset;
  // textMesh1.position.y = 30;
  // textMesh1.position.z = 0;
  textMesh1.position.x = 0;
  textMesh1.position.y = 0;
  textMesh1.position.z = -100;

  textMesh1.rotation.x = 0;
  textMesh1.rotation.y = Math.PI * 2;
  group.add(textMesh1);

  let mirror = false;
  if (mirror) {
    textMesh2 = new THREE.Mesh(textGeo, materials);

    textMesh2.position.x = centerOffset;
    textMesh2.position.y = -30;
    textMesh2.position.z = 20;

    textMesh2.rotation.x = Math.PI;
    textMesh2.rotation.y = Math.PI * 2;

    group.add(textMesh2);
  }
}
export function refreshText(text) {
  group.remove(textMesh1);
  if (!text) return;
  message = text;
  createText(text);
}

function loadFont(text) {
  const loader = new FontLoader();
  loader.load(
    "https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/fonts/optimer_bold.typeface.json",
    function (response) {
      font = response;
      refreshText(text);
    }
  );
}

export const initARScene = (divId, text) => {
  const canvasWidth = document.getElementById(divId).offsetWidth;
  const canvasHeight = document.getElementById(divId).offsetHeight;

  message = text;

  scene = new THREE.Scene();

  camera = new THREE.PerspectiveCamera(
    45,
    canvasWidth / canvasHeight,
    0.1,
    1000
  );
  camera.name = "camera";
  scene.add(camera);

  // lights
  const ambientLight = new THREE.AmbientLight("#ffffff", 1.5);
  scene.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight("#ffffff", 2);
  directionalLight.castShadow = true;
  directionalLight.shadow.camera.near = 1;
  directionalLight.shadow.camera.far = 30;
  directionalLight.shadow.mapSize.set(1024, 1024);
  directionalLight.shadow.normalBias = 0.05;
  directionalLight.position.set(
    camera.position.x,
    camera.position.y,
    camera.position.z
  );
  directionalLight.name = "directionalLight";
  camera.add(directionalLight);

  directionalLight.target = new THREE.Object3D();
  directionalLight.target.position.set(0, 2, 0);
  scene.add(directionalLight.target);

  renderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true,
    powerPreference: "high-performance"
  });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.xr.enabled = true;
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = THREE.sRGBEncoding;
  document.getElementById(divId).appendChild(renderer.domElement);

  new ARButton(renderer, {
    sessionInit: {
      optionalFeatures: ["dom-overlay"],
      domOverlay: { root: document.body }
    }
  });
  document.body.appendChild(ARButton.createButton(renderer));

  materials = [
    new THREE.MeshPhongMaterial({ color: 0x414984, flatShading: true }), // front
    new THREE.MeshPhongMaterial({ color: 0x4a9774 }) // side
  ];

  group = new THREE.Group();

  function onSelect() {
    loadFont(message);
    group.position.set(0, 0, -100).applyMatrix4(controller.matrixWorld);
    group.quaternion.setFromRotationMatrix(controller.matrixWorld);
    scene.add(group);
  }

  controller = renderer.xr.getController(0);
  controller.addEventListener("select", onSelect);
  scene.add(controller);

  window.addEventListener("resize", onWindowResize);
  animate();
};

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);
}

function animate() {
  renderer.setAnimationLoop(render);
}

function render() {
  renderer.render(scene, camera);
}
