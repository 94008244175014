<template>
  <div id="r3D" class="h-100 w-100"></div>
</template>

<script>
import { mapState } from "vuex";
import { initARScene, refreshText } from "@/common/api.ar.js";
import { extractData } from "@/common/api.queries";
import { groupBy, map } from "lodash";

export default {
  name: "AR",
  props: {},
  data() {
    return {};
  },
  mounted: function () {
    let to = new Date();
    let from = new Date();
    from.setMinutes(from.getMinutes() - 3);
    let data = {
      date_from: from.toISOString(),
      date_to: to.toISOString(),
      subsample: 0,
      tags: [],
      voc: 1,
      co2: 1,
      temperature: 1,
      humidity: 1
    };
    console.log("EXTRACT DATA FOR AR");
    extractData(data).then(resp => {
      let measurements = groupBy(resp.body, "device_id");
      for (let device_id in measurements) {
        let data = {};
        data["timestamp"] = map(measurements[device_id], "timestamp");
        data["temperature"] = map(measurements[device_id], "temperature");
        data["humidity"] = map(measurements[device_id], "humidity");
        data["co2"] = map(measurements[device_id], "co2");
        data["voc"] = map(measurements[device_id], "voc");
        this.$store.dispatch("initMeasurement", [device_id, data]);
        let last_value =
          data["temperature"].length > 0
            ? data["temperature"][data["temperature"].length - 1]
            : "";
        const text = `T: ${last_value}°`;
        initARScene("r3D", text);
      }
    });

    // DEV
    // setTimeout(function () {
    //   setInterval(function () {
    //     console.log("REFERESH");
    //     const value = Math.floor(Math.random() * 100);
    //     const text = `T: ${value}°`;
    //     console.log(text);
    //     refreshText(text);
    //   }, 1000);
    // }, 5000);
  },
  computed: {
    ...mapState({
      timestamp(state) {
        return state[this.$route.params.id]["timestamp"];
      },
      temperature(state) {
        return state[this.$route.params.id]["temperature"];
      }
    })
  },
  watch: {
    temperature(value) {
      let last_value = value.length > 0 ? value[value.length - 1] : "";
      const text = `T: ${last_value}°`;
      refreshText(text);
    }
  }
};
</script>

<style scoped>
.black-background {
  background-color: black;
}
</style>
